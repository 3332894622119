<template>
    <slot />
</template>

<script>
import GLightbox from "glightbox"
import Swiper from "swiper/bundle"

export default {
    name: "ImageGallery",
    mounted() {
        const slotDomEl = this.$slots.default()[0].el.parentNode

        slotDomEl.querySelectorAll(".js-slider-gallery").forEach(sliderGallery => {
            this.initSliderGallery(sliderGallery)
        })

        const lightbox = GLightbox({
            touchNavigation: true,
            loop: true,
            closeEffect: "fade",
            openEffect: "fade",
            selector: ".js-gallery-image",
        })
    },

    methods: {
        /**
         * Init slider gallery.
         *
         * @param   {HTMLElement}  sliderGallery
         * @returns  {Void}
         */
        initSliderGallery(sliderGallery) {
            const sliderImagesContainer = sliderGallery.querySelector(".js-slider-gallery-images .swiper")
            const sliderThumbsContainer = sliderGallery.querySelector(".js-slider-gallery-thumbs .swiper")
            if (!sliderImagesContainer || !sliderThumbsContainer) {
                return
            }

            const prevEl = [...sliderImagesContainer.parentNode.querySelectorAll(".js-slider-prev")]
            const nextEl = [...sliderImagesContainer.parentNode.querySelectorAll(".js-slider-next")]
            const pagination = sliderImagesContainer.querySelector(".swiper-pagination")

            if (!sliderImagesContainer || !sliderThumbsContainer) {
                return
            }

            const swiperThumbs = new Swiper(sliderThumbsContainer, {
                slidesPerView: 6,
                spaceBetween: 16,
                watchOverflow: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
            })

            var slider2 = new Swiper(sliderImagesContainer, {
                slidesPerView: 1,
                spaceBetween: 25,
                direction: "horizontal",
                watchOverflow: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                preventInteractionOnTransition: true,
                loop: true,
                horizontal: false,
                pagination: {
                    el: pagination,
                    dynamicBullets: false,
                    clickable: true,
                },

                thumbs: {
                    swiper: swiperThumbs,
                },
                ...(prevEl && nextEl
                    ? {
                          navigation: { prevEl, nextEl },
                      }
                    : {}),
            })

            if (sliderThumbsContainer.querySelectorAll(".swiper-slide").length <= 6) {
                sliderThumbsContainer.classList.add("centered")
            }

            if (document.querySelectorAll(".js-sticky-gallery").length > 0) {
                const stickyGallery = document.querySelectorAll(".js-sticky-gallery")[0]

                setTimeout(() => {
                    this.checkStickyGalleryPosition(stickyGallery)
                }, 200)

                document.addEventListener("scroll", () => {
                    this.checkStickyGalleryPosition(stickyGallery)
                })
            }
        },

        checkStickyGalleryPosition(stickyGallery) {
            let scrollOffset = window.scrollY
            let stickyGalleryHeight = stickyGallery.querySelector(".sticky-container").clientHeight
            let moduleStageHeight = document.querySelector(".module-stage").clientHeight
            let moduleStageMargin = window.getComputedStyle(document.querySelector(".stage-gallery-wrapper")).marginBottom.replace("px", "")
            let stickyGalleryOffset = moduleStageHeight + Number(moduleStageMargin) - stickyGalleryHeight
            let calculatedGalleryOffset = scrollOffset + moduleStageHeight * 0.3 + stickyGalleryHeight
            let endElement = document.querySelectorAll(".js-stop-sticky-stage")[0]
            let endElementOffset = endElement.offsetTop + endElement.clientHeight

            if (calculatedGalleryOffset >= endElementOffset) {
                stickyGallery.style.position = "absolute"
                stickyGallery.style.top = endElementOffset - stickyGalleryHeight + "px"
            } else {
                stickyGallery.style.position = "fixed"
                stickyGallery.style.top = moduleStageHeight * 0.3 + "px"
            }
        },

        /**
         * Init
         */
        // initSliderGallery({
        // 	sliderImages: {
        // 		selector: ".js-slider-gallery-images .swiper",
        // 	},
        // 	sliderThumbs: {
        // 		selector: ".js-slider-gallery-thumbs .swiper",
        // 	},
        // })
    },
}
</script>
