<template>
    <component
        :is="icon"
        class="w-auto fill-current outline-none focus:outline-none"
        :class="
            small
                ? 'h-icon-size-sm max-w-icon-size-sm'
                : large
                  ? 'h-icon-size-lg max-w-icon-size-lg'
                  : custom
                    ? custom
                    : auto
                      ? ''
                      : 'h-icon-size-md max-w-icon-size-md'
        "
        @click="emit('click', $event)" />
</template>

<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        name: string
        small?: boolean
        large?: boolean
        auto?: boolean
        custom?: string
    }>(),
    {
        small: false,
        large: false,
        auto: false,
        custom: null,
    }
)

const emit = defineEmits<{
    click: [ev: MouseEvent]
}>()

const icon = defineAsyncComponent(() => import(`../../icons/${props.name}.svg`))

/*
const getImageUrl = (source, name) => {
  console.log("getImageUrl: " + source + " :: " + name)
  return new URL("/icons/" + source + "/" + name + ".svg", import.meta.url).href
}

onMounted(() => {
  console.debug("Icon.vue mounted", getImageUrl(props.source, props.name))
})
 */
</script>
