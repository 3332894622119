// any CSS you import will output into a single css file (app.css in this case)
import "../sass/app.scss"

import { createApp } from "vue"
import { createI18n } from "vue-i18n"
import { ConditionalLogic, FetchManager, FriendlyCaptcha } from "js-pimcore-formbuilder"
import ImageGallery from "@components/ImageGallery.vue" // we have to include the ImageGallery to solve multi nested component issues with Accordions
import DownloadButtons from "@components/DownloadButtons.vue"
import SvgIcon from "@components/SvgIcon.vue"
import "@/components/fadeAnimation.ts"

// Read the lang attribute from the <html> tag
const htmlLang = document.documentElement.lang.replace("-", "_") || "de_DE"

// Dynamically import the translation file for the current language
const loadTranslation = async (lang) => {
    const translations = await import(`./locales/${lang}.json`)
    return translations.default
}

const initFormBuilderForms = () => {
    document.addEventListener("DOMContentLoaded", () => {
        document.querySelectorAll(".formbuilder.ajax-form").forEach(form => {
            new ConditionalLogic(form)
            new FetchManager(form)
            new FriendlyCaptcha(form)
        })
    })
}

async function initApp() {
    const messages = { [htmlLang]: await loadTranslation(htmlLang) }

    const i18n = createI18n({
        locale: htmlLang,
        fallbackLocale: "de_DE",
        messages,
    })

    class Main {
        constructor() {
            this.initVueContentComponents()
        }

        initVueContentComponents() {
            const allVueComponents = import.meta.glob("./components/*.vue", { import: "default" })
            const twigComponents = document.querySelectorAll(".vue-component")
            twigComponents.forEach(component => {
                // remove "class" attribute from element to prevent vue warnings
                component.removeAttribute("class")

                // convert the element tag name to a Pascal case component name
                const componentName = this.toPascalCase(component.tagName)

                // create the vue app for the twig template vue component based on componentName build from component html tag name
                allVueComponents["./components/" + componentName + ".vue"]().then(componentObject => {
                    const app = createApp({
                        delimiters: ["${", "}"],
                        template: component.outerHTML,
                    })

                    app.use(i18n) // Add i18n to the app

                    // Provide locale to all components
                    app.provide("locale", htmlLang.replaceAll("_", "-"))

                    app.component("download-buttons", DownloadButtons)
                    app.component("image-gallery", ImageGallery)
                    app.component("svg-icon", SvgIcon)

                    app.component(componentName, componentObject)

                    // Extract content from <template> tags and append to the component
                    const templates = component.querySelectorAll("template")
                    const fragment = document.createDocumentFragment()
                    templates.forEach(template => {
                        const content = document.importNode(template.content, true)
                        fragment.appendChild(content)
                    })

                    // Find and initialize nested components within slots and templates
                    const nestedComponents = Array.from(component.querySelectorAll(".vue-component"))
                        .concat(Array.from(fragment.querySelectorAll(".vue-component")))
                    const nestedComponentPromises = nestedComponents.map(nestedComponent => {
                        nestedComponent.removeAttribute("class")
                        const nestedComponentName = this.toPascalCase(nestedComponent.tagName)
                        return allVueComponents["./components/" + nestedComponentName + ".vue"]().then(nestedComponentObject => {
                            app.component(nestedComponentName, nestedComponentObject)
                        })
                    })

                    // Wait for all nested components to be loaded before mounting the app
                    Promise.all(nestedComponentPromises).then(() => {
                        app.mount(component)
                    })
                })
            })
        }

        toPascalCase(input: string): string {
            return input
                .split("-") // Split the string at the dashes
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Convert the first letter of each word to uppercase and the rest to lowercase
                .join("") // Connect the words without separator
        }
    }

    let main = new Main()
}

initApp()
initFormBuilderForms()
