<template>
    <div class="mt-6 flex flex-row gap-8">
        <a
            v-if="isVideo"
            :href="copyLink"
            class="button textlink text-gutex-rot js-lightbox focus:ring-0 focus:[box-shadow:none] focus:hover:[box-shadow:none]"
            ><svg-icon name="play" class="vue-component" /><span>{{ playButtonLabel }}</span></a
        >

        <a
            v-if="!isVideo"
            :href="assetPath"
            target="_blank"
            class="button textlink text-gutex-rot focus:ring-0 focus:[box-shadow:none] focus:hover:[box-shadow:none]"
            ><svg-icon name="download" class="vue-component" /><span>{{ downloadButtonLabel }}</span></a
        >

        <a
            :href="copyLink"
            class="button textlink js-copy-clipboard focus:ring-0 focus:[box-shadow:none] focus:hover:[box-shadow:none]"
            @click.prevent="copyURI"
            ><svg-icon name="link" class="vue-component" /><span>{{ shareButtonLabel }}</span></a
        >

        <transition name="expand" mode="out-in">
            <div v-if="showInfo" class="absolute left-0 top-full z-10 w-full bg-sand-80">
                <div class="flex justify-center px-9 py-1.5">
                    <div class="mb-0 ml-auto mr-0 mt-0 flex gap-x-2">
                        <svg-icon name="checkbox-selected" />
                        {{ linkCopiedInfo }}
                    </div>

                    <div class="ml-auto cursor-pointer" @click="hideInfo">
                        <svg-icon name="x" class="stroke-current" />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup lang="ts">
import SvgIcon from "@components/SvgIcon.vue"
import GLightbox from "glightbox"

defineProps<{
    isVideo?: boolean
    assetPath?: string
    copyLink: string
    shareButtonLabel: string
    downloadButtonLabel?: string
    playButtonLabel?: string
    linkCopiedInfo: string
}>()

let showInfo = ref(false)

onMounted(() => {
    initLightbox()
})

function initLightbox() {
    const lightbox = GLightbox({
        touchNavigation: true,
        loop: true,
        selector: ".js-lightbox",
        autoplayVideos: false,
    })
}

function copyURI(evt) {
    navigator.clipboard.writeText(evt.target.closest(".js-copy-clipboard").getAttribute("href"))
    showInfo.value = true

    setTimeout(() => {
        showInfo.value = false
    }, 3000)
}

function hideInfo() {
    showInfo.value = false
}
</script>

<style scoped lang="scss">
.expand-leave-active,
.expand-enter-active {
    @apply overflow-hidden transition-all;
}

.expand-enter-to,
.expand-leave-from {
    @apply max-h-16;
}

.expand-enter-from,
.expand-leave-to {
    @apply max-h-0;
}
</style>
